import Button from 'devextreme-react/button';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as ApplicationApi from 'api/applicationApi';
import { PrimaryButton } from 'components/StandardButton';
import PageTitle from 'components/PageTitle';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import * as ApplicationSourceConstant from 'data/applicationSourceConstant';
import AccountBookNotSelected from 'layouts/AccountBookNotSelected';
import * as ErrorMessage from 'utils/errorMessage';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as AnnualReturnApi from './api/annualReturnApi';
import * as annualReturnStatusConstant from './data/annualReturnStatusConstant';
import AcraFilingQualificationCriteria from './partials/AcraFilingQualificationCriteria';
import AnnualReturnFormView from './partials/AnnualReturnFormView';
import AnnualReturnPaymentView from './partials/AnnualReturnPaymentView';
import InformationBanner from './partials/InformationBanner';

const AnnualReturnPage = () => {
    const [annualReturnData, setAnnualReturnData] = useState(null);

    const { accountBookData, refreshAccountBookData, isPriorYA } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;
    const isAccountMappingComplete = accountBookData && accountBookData.isAccountMappingComplete;

    const navigate = useNavigate();

    useEffect(() => {
        if (
            accountBookData.applicationSource === ApplicationSourceConstant.CloudAccounting &&
            accountBookData.applicationSource === ApplicationSourceConstant.OnPremiseAccounting &&
            annualReturnData?.status === annualReturnStatusConstant.FormInitiated &&
            accountBookData.stepList.includes(AccountBookDataStatusConstant.InitiatedAnnualReturn) &&
            !accountBookData.stepList.includes(AccountBookDataStatusConstant.VisitedFinancialStatement)) {
            MessageBox.showConfirmMessage('Do you want to submit Financial Statement before proceeding to Annual Return?')
                .then(result => {
                    if (result.confirm) navigate('/financialStatement');
                    ApplicationApi.updateAccountBookDataStatus(accountBookDataId, AccountBookDataStatusConstant.VisitedFinancialStatement)
                        .then(() => refreshAccountBookData());
                });
        }
    }, [annualReturnData?.status, accountBookData.stepList]);

    const loadData = useCallback(() => {
        if (accountBookDataId) {
            PageUtils.blockPage();
            AnnualReturnApi.getAnnualReturnData(accountBookDataId)
                .then(data => {
                    PageUtils.unblockPage();
                    setAnnualReturnData({
                        status: data.status,
                        profileData: data.profileInJson ? JSON.parse(data.profileInJson) : null,
                        annualReturnFormDraft: data.formDraftInJson ? JSON.parse(data.formDraftInJson) : null,
                        previewFileInfoData: data.previewFileInfoInJson ? JSON.parse(data.previewFileInfoInJson) : null,
                        formResultData: data.formResultInJson ? JSON.parse(data.formResultInJson) : null,
                        enquirePaymentData: data.enquirePaymentInJson ? JSON.parse(data.enquirePaymentInJson) : null,
                        requireNewFiling: data.requireNewFiling,
                        financialStatementList: data.financialStatementList
                    });
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.fetchError(error));
                });
        }
    }, [accountBookDataId]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const showFormView = annualReturnData &&
        (
            annualReturnData.status === annualReturnStatusConstant.FormInitiated ||
            annualReturnData.status === annualReturnStatusConstant.FormPending
        );
    const showPaymentView = annualReturnData &&
        (
            annualReturnData.status === annualReturnStatusConstant.FormSubmitted ||
            annualReturnData.status === annualReturnStatusConstant.PaymentPending ||
            annualReturnData.status === annualReturnStatusConstant.PaymentFailed ||
            annualReturnData.status === annualReturnStatusConstant.PaymentCompleted
        );

    function initiateAnnualReturn() {
        PageUtils.blockPage();

        AnnualReturnApi.initiateAnnualReturnFiling(accountBookDataId)
            .then(() => {
                PageUtils.unblockPage();
                loadData();
                refreshAccountBookData();
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    function resetFiling() {
        MessageBox.showConfirmDangerMessage('Are you sure you want to reset Annual Return ? (This action can\'t be reverted).', false, 'Yes, proceed', 'No, keep it')
            .then(result => {
                if (result.confirm) {
                    PageUtils.blockPage();

                    AnnualReturnApi.deleteDraft(accountBookDataId)
                        .then(() => {
                            PageUtils.unblockPage();
                            Toast.showSuccessMessage('Annual Return Filing is reset successfully.');
                            loadData();
                            refreshAccountBookData();
                        })
                        .catch(error => {
                            PageUtils.unblockPage();
                            Toast.showErrorMessage(ErrorMessage.deleteError(error));
                        });
                }
            });
    }

    return (
        <>
            <div className="content-block">
                <InformationBanner />
                <PageTitle title="Annual Return with XBRL FS">
                    <div className="align-self-center" style={{ flexGrow: 100 }}>
                        <PrimaryButton
                            visible={annualReturnData && annualReturnData.status !== annualReturnStatusConstant.FormNew}
                            className="ms-3"
                            text="Financial Statement"
                            onClick={() => navigate('/financialStatement')}
                        />
                    </div>
                    <div className="align-self-center">
                        <Button
                            width={170}
                            visible={annualReturnData &&
                                annualReturnData.status !== annualReturnStatusConstant.FormNew &&
                                annualReturnData.status !== annualReturnStatusConstant.PaymentPending &&
                                annualReturnData.status !== annualReturnStatusConstant.PaymentCompleted
                            }
                            className="ms-3 mb-1 mb-md-0"
                            text="Reset Filing"
                            onClick={() => resetFiling()}
                        />
                    </div>
                </PageTitle>
            </div >
            {
                isAccountMappingComplete ?
                    <>
                        {
                            accountBookDataId && annualReturnData ?
                                <div className="content-block">
                                    {(annualReturnData.status === annualReturnStatusConstant.FormNew) &&
                                        <div className="dx-card responsive-paddings">
                                            <div className="dx-card px-3 pt-3 mb-3">
                                                <AcraFilingQualificationCriteria />
                                            </div>
                                            {!isPriorYA &&
                                                <>
                                                    <div className="alert alert-primary p-3">
                                                        Please click on Initiate Annual Return Filing to start filing.
                                                    </div>
                                                    <PrimaryButton
                                                        text="Initiate Annual Return Filing"
                                                        onClick={initiateAnnualReturn}
                                                    />
                                                </>
                                            }
                                        </div>
                                    }

                                    {showFormView &&
                                        <AnnualReturnFormView
                                            initialData={annualReturnData}
                                            loadData={loadData}
                                        />
                                    }
                                    {showPaymentView &&
                                        <AnnualReturnPaymentView
                                            initialData={annualReturnData}
                                            loadData={loadData}
                                        />
                                    }
                                </div> :
                                <AccountBookNotSelected />
                        }
                    </>
                    :
                    <div className="content-block">
                        <div className="alert alert-danger"><p className="fw-bold d-inline">Account Mapping</p>  is not complete. Please proceed in <p className="d-inline text-decoration-underline" role="button" onClick={() => navigate('/accountMappingMaintenance')}>Account Mapping Maintenance</p>.</div>
                    </div>}
        </>
    );
};

export default AnnualReturnPage;
