import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { TextBox } from 'devextreme-react/text-box';
import { NumberBox } from 'devextreme-react/number-box';
import { Validator, RequiredRule, RangeRule } from 'devextreme-react/validator';
import { Tooltip } from 'devextreme-react/tooltip';
import PageTitle from 'components/PageTitle';
import { SubmitButton } from 'components/StandardButton';
import StandardDateBox from 'components/StandardDateBox';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import AccountBookNotSelected from 'layouts/AccountBookNotSelected';
import * as ErrorMessage from 'utils/errorMessage';
import * as Toast from 'utils/toast';
import * as AccountBookDataApi from './api/accountBookDataApi';
import * as DateUtils from 'utils/dateUtils';
import * as MessageBox from 'utils/messageBox';
import * as PageUtils from 'utils/pageUtils';
import FinancialDataView from './FinancialDataView';

const AccountBookDataPage = () => {
    const [financialData, setFinancialData] = useState(null);
    const [accountBookData, setAccountBookData] = useState(null);
    const [isCurrentYear, setIsCurrentYear] = useState(true);
    const uenValidationRef = useRef();

    const { accountBookData: contextAccountBookData, refreshAccountBookData: contextRefreshAccountBookData, isPriorYA } = useAccountBookData();
    const accountBookDataId = contextAccountBookData && contextAccountBookData.accountBookDataId;
    const readOnly = contextAccountBookData.stepList.some(x => x === AccountBookDataStatusConstant.InitiatedAnnualReturn || x === AccountBookDataStatusConstant.InitiatedFormCS) || isPriorYA;

    const navigate = useNavigate();

    useEffect(() => {
        setIsCurrentYear(true);

        if (accountBookDataId) {
            PageUtils.blockPage();

            AccountBookDataApi.getAccountBookData(accountBookDataId)
                .then(data => {
                    PageUtils.unblockPage();
                    const { financialData, ...restData } = data;
                    setFinancialData({
                        hasPriorYearFinancialData: financialData.priorYearStartDate && financialData.priorYearEndDate,
                        currentYearStartDate: financialData.currentYearStartDate,
                        currentYearEndDate: financialData.currentYearEndDate,
                        priorYearStartDate: financialData.priorYearStartDate,
                        priorYearEndDate: financialData.priorYearEndDate,
                        currentYearProfitAndLossStatement: JSON.parse(financialData.currentYearProfitAndLossStatement),
                        currentYearBalanceSheetStatement: JSON.parse(financialData.currentYearBalanceSheetStatement),
                        currentYearDebtorAccountBalance: JSON.parse(financialData.currentYearDebtorAccountBalance),
                        currentYearCreditorAccountBalance: JSON.parse(financialData.currentYearCreditorAccountBalance),
                        priorYearProfitAndLossStatement: financialData.priorYearProfitAndLossStatement ? JSON.parse(financialData.priorYearProfitAndLossStatement) : null,
                        priorYearBalanceSheetStatement: financialData.priorYearBalanceSheetStatement ? JSON.parse(financialData.priorYearBalanceSheetStatement) : null,
                        priorYearDebtorAccountBalance: financialData.priorYearDebtorAccountBalance ? JSON.parse(financialData.priorYearDebtorAccountBalance) : null,
                        priorYearCreditorAccountBalance: financialData.priorYearCreditorAccountBalance ? JSON.parse(financialData.priorYearCreditorAccountBalance) : null,
                        accountBookData: restData
                    });
                    setAccountBookData(restData);
                })
                .catch(error => {
                    PageUtils.unblockPage();
                    Toast.showErrorMessage(ErrorMessage.fetchError(error));
                    setFinancialData(null);
                });
        } else {
            setFinancialData(null);
        }
    }, [accountBookDataId]);

    function handleSubmit(e) {
        e.preventDefault();
        saveRecord(financialData.accountBookData, accountBookData);
    }

    function saveRecord(original, current) {
        PageUtils.blockPage();
        AccountBookDataApi.updateAccountBookData(original.accountBookDataId, current)
            .then(() => {
                PageUtils.unblockPage();
                contextRefreshAccountBookData();
                Toast.showSuccessMessage('Account Book Data is updated successfully.');

                if (!contextAccountBookData.stepList.includes(AccountBookDataStatusConstant.CompletedCompanyInfo)) {
                    MessageBox.showConfirmMessage('Account Book Data is completed, do you want to proceed to Account Mapping?')
                        .then(result => {
                            if (result.confirm) navigate('/accountMappingMaintenance');
                        });
                }
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.updateError(error));
            });
    }

    return (
        <>
            <div className="content-block">
                <PageTitle title="Account Book Data" isRestrictedPage />
            </div>
            {accountBookDataId ?
                <>
                    <div className="content-block">
                        {financialData && financialData.accountBookData &&
                            <div className="dx-card responsive-paddings">
                                <form id="accountBookDataForm" onSubmit={handleSubmit}>
                                    <div className="mb-3 row">
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Company Name</label>
                                            <i id="application" className="dx-icon dx-icon-info ms-1" style={{ fontSize: '13px' }}></i>
                                            <Tooltip
                                                target="#application"
                                                showEvent="mouseenter"
                                                hideEvent="mouseleave"
                                                hideOnOutsideClick={false}
                                            >
                                                Application: {financialData.accountBookData.applicationSource}
                                            </Tooltip>
                                            <TextBox
                                                value={financialData.accountBookData.companyName}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Assessment Year</label>
                                            <NumberBox
                                                value={financialData.accountBookData.yearOfAssessment}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Company ID</label>
                                            <TextBox
                                                value={financialData.accountBookData.companyId}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">UEN</label>
                                            <TextBox
                                                value={accountBookData.uen}
                                                onValueChanged={e => setAccountBookData({ ...accountBookData, uen: e.value })}
                                                maxLength={20}
                                                readOnly={readOnly}
                                            >
                                                <Validator ref={uenValidationRef}>
                                                    <RequiredRule message="UEN is required." />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Current Year Start Date</label>
                                            <StandardDateBox
                                                value={accountBookData.currentYearActualDataStartDate}
                                                type="date"
                                                onValueChanged={e => setAccountBookData({ ...accountBookData, currentYearActualDataStartDate: e.value })}
                                                readOnly={readOnly}
                                            >
                                                <Validator>
                                                    <RangeRule
                                                        message="Current Year Start Date must be before Current Year End Date."
                                                        max={DateUtils.addDays(financialData.accountBookData.currentYearEndDate, -1)}
                                                    />
                                                </Validator>
                                            </StandardDateBox>
                                            <div className="form-text">Day of month is allowed to modify to keep align with ACRA record for date of incorporation. .</div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Current Year End Date</label>
                                            <StandardDateBox
                                                value={financialData.accountBookData.currentYearEndDate}
                                                type="date"
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Prior Year Start Date</label>
                                            <StandardDateBox
                                                value={accountBookData.priorYearActualDataStartDate}
                                                type="date"
                                                onValueChanged={e => setAccountBookData({ ...accountBookData, priorYearActualDataStartDate: e.value })}
                                                readOnly={!accountBookData.priorYearEndDate || readOnly}
                                            >
                                                <Validator>
                                                    <RangeRule
                                                        message="Prior Year Start Date must be before Prior Year End Date."
                                                        max={DateUtils.addDays(financialData.accountBookData.priorYearEndDate, -1)}
                                                    />
                                                </Validator>
                                            </StandardDateBox>
                                            <div className="form-text">Day of month is allowed to modify to keep align with ACRA record for date of incorporation. .</div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <label className="form-label">Prior Year End Date</label>
                                            <StandardDateBox
                                                value={financialData.accountBookData.priorYearEndDate}
                                                type="date"
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div id="submitAccountBookData" className="mt-3" style={{ display: 'inline-block' }}>
                                    <SubmitButton
                                        text="Update Account Book Data"
                                        submitForm="accountBookDataForm"
                                        disabled={readOnly}
                                    />
                                    {!isPriorYA && readOnly &&
                                        <Tooltip
                                            target="#submitAccountBookData"
                                            showEvent="mouseenter"
                                            hideEvent="mouseleave"
                                            hideOnOutsideClick={false}
                                        >
                                            <div>Update is disabled due to filing is in progress or completed</div>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <FinancialDataView
                        accountBookDataId={accountBookDataId}
                        financialData={financialData}
                        isCurrentYear={isCurrentYear}
                        setIsCurrentYear={setIsCurrentYear}
                    />
                </> :
                <AccountBookNotSelected />
            }
        </>
    );
};

export default AccountBookDataPage;