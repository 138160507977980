import { Column, ColumnChooser, Scrolling, Toolbar, Item } from 'devextreme-react/tree-list';
import { CheckBox } from 'devextreme-react/check-box';
import { useEffect, useMemo, useState, useRef } from 'react';
import StandardTreeList from 'components/StandardTreeList';
import disabledPdeConstant from './data/disabledPdeConstant';

const ReadOnlyAccountMappingGrid = (props) => {
    const { initialData, accountMappingData } = props;
    const [viewFilter, setViewFilter] = useState({ showMappedPde: true, showUnmappedPde: true });
    const treeListRef = useRef();

    const viewData = useMemo(() => {
        const viewData = [];
        initialData.pdeList.forEach(x => {
            if (!disabledPdeConstant.includes(x.dataId)) {
                viewData.push({
                    key: x.dataId,
                    description: `PDE: ${x.description}`,
                    isHeader: true
                });
            }
        });

        accountMappingData.forEach(x => {
            const accountData = initialData.chartOfAccountData.find(y => y.accNo === x.accNo);
            viewData.push({
                key: x.accNo,
                parentKey: x.pde,
                accNo: x.accNo,
                description: accountData.description,
                accTypeDescription: accountData.accTypeDescription,
                liquidity: x.liquidity
            });
        });

        return viewData;
    }, []);

    useEffect(() => {
        if (viewFilter.showMappedPde && viewFilter.showUnmappedPde)
            treeListRef.current.instance.filter(null);
        else if (!viewFilter.showMappedPde && !viewFilter.showUnmappedPde)
            treeListRef.current.instance.filter([]);
        else {
            const filter = [];
            const pdeFilter = [];
            const mappedPdeList = accountMappingData.map(x => x.pde);

            if (viewFilter.showMappedPde) {
                for (let i = 0; i < mappedPdeList.length; i++) {
                    if (i !== 0) pdeFilter.push('or');
                    pdeFilter.push(['key', '=', mappedPdeList[i]]);
                }

                filter.push([
                    [
                        ['isHeader', '=', true],
                        'and',
                        pdeFilter
                    ],
                    'or',
                    ['parentKey', '<>', null]
                ]);
            } else if (viewFilter.showUnmappedPde) {
                for (let i = 0; i < mappedPdeList.length; i++) {
                    if (i !== 0) pdeFilter.push('and');
                    pdeFilter.push(['key', '<>', mappedPdeList[i]]);
                }

                filter.push([
                    [
                        ['isHeader', '=', true],
                        'and',
                        pdeFilter
                    ],
                    'and',
                    ['parentKey', '=', null]
                ]);
            }

            treeListRef.current.instance.filter(filter);
        }
    }, [viewFilter.showMappedPde, viewFilter.showUnmappedPde]);

    return (
        <div className="row">
            <StandardTreeList
                keyExpr="key"
                parentIdExpr="parentKey"
                autoExpandAll
                dataSource={viewData}
                allowColumnReordering={false}
                height="calc(100vh - 283px)"
                onRowPrepared={e => {
                    if (e.rowType === 'data' && e.data.isHeader)
                        e.rowElement.classList.add('account-mapping-header-row');
                }}
                ref={treeListRef}
            >
                <ColumnChooser enabled={false} />
                <Scrolling mode="virtual" />

                <Toolbar>
                    <Item
                        location="before"
                        locateInMenu="auto"
                    >
                        <div className="d-flex">
                            <CheckBox
                                text="Show Mapped PDE"
                                value={viewFilter.showMappedPde}
                                onValueChanged={(e) => setViewFilter(prevState => ({ ...prevState, showMappedPde: e.value }))}
                            />
                        </div>
                    </Item>
                    <Item
                        location="before"
                        locateInMenu="auto"
                    >
                        <div className="d-flex">
                            <CheckBox
                                text="Show Unmapped PDE"
                                value={viewFilter.showUnmappedPde}
                                onValueChanged={(e) => setViewFilter(prevState => ({ ...prevState, showUnmappedPde: e.value }))}
                            />
                        </div>
                    </Item>
                    <Item name="searchPanel" />
                </Toolbar>
                <Column
                    caption="Description"
                    dataField="description"
                    dataType="string"
                    sortOrder="asc"
                    cellRender={e => {
                        return (
                            <>
                                {e.data.description}
                                {e.data.liquidity &&
                                    <div style={{ lineHeight: '14px' }} className="badge bg-dark text-light fw-normal ms-2 align-self-center">
                                        {e.data.liquidity}
                                    </div>
                                }
                            </>
                        );
                    }}
                />
                <Column
                    caption="Acc. No."
                    dataField="accNo"
                    dataType="string"
                    width="120px"
                />
                <Column
                    caption="Acc. Type"
                    dataField="accTypeDescription"
                    dataType="string"
                    width="300px"
                />
            </StandardTreeList>
        </div >
    );
};

export default ReadOnlyAccountMappingGrid;