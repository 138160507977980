import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const PartBPdfView = (props) => {
    const { formData } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        normalText: {
            fontSize: 8,
            margin: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const dataSource = [
        {
            label: 'Net Profit/ Loss before Tax as per Financial Statements',
            value: formData.dataFormCS.profitLossBeforeTaxation
        },
        {
            adjustment: 'Less:',
            label: 'Separate Source Income',
            value: formData.dataFormCS.sepSrcIncome
        },
        {
            label: 'Non-Taxable Income',
            value: formData.dataFormCS.receiptNotTxAmt
        },
        {
            adjustment: 'Add:',
            label: 'Non-Tax Deductible Expenses',
            value: formData.dataFormCS.c1_NTDeductibleExp
        },
        {
            label: 'Adjusted Profit/ Loss before Other Deductions',
            value: formData.dataFormCS.adjPLBefDed
        },
        {
            label: 'Deduction for Renovation or Refurbishment Works under Section 14Q',
            value: formData.dataFormCS.renoWorksExpS14Q
        },
        {
            label: 'Enhanced Deductions under Enterprise Innovation Scheme (EIS) for Training; Innovation Projects carried out with Partner Institutions; Licensing of Intellectual Property Rights; Registration of Intellectual Property; Qualfiying R&D undertaken in Singapore',
            value: formData.dataFormCS.c1_EnhancedEISDed,
            field: 'c1_EnhancedEISDed'
        },
        {
            label: 'Further Deductions/ Other Deductions including revenue expenses capitalised or expenses incurred under section 14U',
            value: formData.dataFormCS.c1_FurtherDed
        },
        {
            label: 'Adjusted Profit/ Loss before Capital Allowances',
            value: formData.dataFormCS.sgAdjPLAft
        },
        {
            adjustment: 'Add:',
            label: 'Balancing Charge',
            value: formData.dataFormCS.c1_BC
        },
        {
            adjustment: 'Less:',
            label: 'Unutilised Capital Allowances brought forward (IRAS record)',
            value: formData.prefillData.unutilCABFNorm
        },
        {
            label: 'Company\'s Declaration',
            value: formData.dataFormCS.unutilCABFNorm
        },
        {
            label: 'Current Year Capital Allowance',
            value: formData.dataFormCS.cyCANorm
        },
        {
            label: 'Unutilised Losses brought forward (IRAS\' record)',
            value: formData.prefillData.unutilLossBFNorm
        },
        {
            label: 'Company\'s Declaration',
            value: formData.dataFormCS.unutilLossBFNorm
        },
        {
            adjustment: 'Add:',
            label: 'Separate Source Income'
        },
        {
            label: 'Gross Rental Income',
            value: formData.dataFormCS.c1_GrossRent
        },
        {
            adjustment: 'Less:',
            label: 'Deductible Expenses',
            value: formData.dataFormCS.c1_DedExp
        },
        {
            label: 'Net Rental Income',
            value: formData.dataFormCS.sgRent
        },
        {
            label: 'Interest Income',
            value: formData.dataFormCS.sgIntDisc
        },
        {
            label: 'Other Taxable Income',
            value: formData.dataFormCS.sgOtherI
        },
        {
            label: 'Total Income/ Losses (before Donations)',
            value: formData.dataFormCS.totSgFI
        },
        {
            adjustment: 'Less:',
            label: 'Unutilised Donations brought forward (IRAS\' record) ',
            value: formData.prefillData.unutilDonationBFNorm
        },
        {
            label: 'Company\'s Declaration',
            value: formData.dataFormCS.unutilDonationBFNorm
        },
        {
            adjustment: 'Less:',
            label: 'Current Year Donation (IRAS\' record) ',
            value: formData.prefillData.cyDonation
        },
        {
            label: 'Company\'s Declaration',
            value: formData.dataFormCS.cyDonation
        },
        {
            label: 'Total Income/ Losses (after Donations)',
            value: formData.dataFormCS.ci
        },
        {
            label: 'Unutilised Capital Allowances carried forward',
            value: formData.dataFormCS.unutilCACFNorm
        },

        {
            label: 'Unutilised Losses carried forward',
            value: formData.dataFormCS.unutilLossCFNorm
        },
        {
            label: 'Unutilised Donations carried forward',
            value: formData.dataFormCS.unutilDonationCFNorm
        }
    ];

    const generateRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: 30 }]}>
                    <Text>{data.adjustment ?? null} </Text>
                </View>
                <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start' }]}>
                    <Text>{data.label}</Text>
                </View>
                <View style={[styles.normalText, { width: 100, alignItems: 'flex-end' }]}>
                    <Text>{data.value !== undefined ? formatValue(data.value) : null}</Text>
                </View>
            </View>
        );
    };

    const generate_c1_EnhancedEISDedRow = () => {
        return (
            <>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, paddingBottom: 1 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingBottom: 1 }]}>
                        <Text>Enhanced Deductions under Enterprise Innovation Scheme (EIS) for:</Text>
                    </View>
                    <View style={[styles.normalText, { width: 100, alignItems: 'flex-end', paddingBottom: 1 }]}>
                        <Text>{formatValue(formData.dataFormCS.c1_EnhancedEISDed)}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, paddingBottom: 2 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3, paddingBottom: 2 }]}>
                        <Text>{'\u2022' + '  Training;'}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, paddingBottom: 2 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3, paddingBottom: 2 }]}>
                        <Text>{'\u2022' + '  Innovation Projects carried out with Partner Institutions;'}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, paddingBottom: 2 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3, paddingBottom: 2 }]}>
                        <Text>{'\u2022' + '  Licensing of Intellectual Property Rights;'}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, paddingBottom: 2 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3, paddingBottom: 2 }]}>
                        <Text>{'\u2022' + '  Registration of Intellectual Property;'}</Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, paddingBottom: 2 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3, paddingBottom: 2 }]}>
                        <Text>{'\u2022' + '  Qualifying R&D undertaken in Singapore;'}</Text>
                    </View>
                </View>
            </>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Part B</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={styles.tableHeader}>
                    <Text style={styles.tableHeaderText}>Tax Adjustment</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                    {dataSource.map(data => {
                        if (data.field === 'c1_EnhancedEISDed') return generate_c1_EnhancedEISDedRow();

                        return generateRow(data);
                    })};
                </View>
            </View>
        </Page>
    );
};

export default PartBPdfView;