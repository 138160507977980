import { useMemo } from 'react';
import { Button } from 'devextreme-react/button';
import { Column } from 'devextreme-react/data-grid';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { ToolbarItem } from 'devextreme-react/popup';
import StandardPopup from 'components/StandardPopup';
import FinancialScheduleGrid from '../components/FinancialScheduleGrid';

const CapitalAllowanceSchedule = (props) => {
    const { initialData, onCancel } = props;

    const data = useMemo(() => {
        const hpDataSource = {
            additionsDuringTheYear: [...initialData.hpAdditionsDuringTheYear.hpOtherPPE],
            priorYearAdditions: [...initialData.hpPriorYearAdditions.hpOtherPPE],
            disposalsDuringTheYear: [...initialData.hpDisposalsDuringTheYear.hpOtherPPE]
        };

        const nonHPDataSource = {
            additionsDuringTheYear: [
                ...initialData.nonHPAdditionsDuringTheYear.nonHPCompCommEquipment,
                ...initialData.nonHPAdditionsDuringTheYear.nonHpOtherPPE_LowValueAsset,
                ...initialData.nonHPAdditionsDuringTheYear.nonHpOtherPPE
            ],
            priorYearAdditions: [...initialData.nonHPPriorYearAdditions.nonHpOtherPPE],
            disposalsDuringTheYear: [
                ...initialData.nonHPDisposalsDuringTheYear.nonHPCompCommEquipment,
                ...initialData.nonHPDisposalsDuringTheYear.nonHpOtherPPE_LowValueAsset,
                ...initialData.nonHPDisposalsDuringTheYear.nonHpOtherPPE
            ]
        };

        return {
            hpDataSource,
            nonHPDataSource
        };
    }, []);

    function calculateBalancingAllowanceOrBalancingChargeCellValue(rowData) {
        if (rowData.salesProceedEachAsset > rowData.taxWrittenDownValueBFEachAsset)
            return rowData.balancingChargeEachAssetDisposedOfOrWrittenOff;
        else
            return rowData.balancingAllowanceEachAssetDisposedOfOrWrittenOff;
    }

    return (
        <StandardPopup
            width="80vw"
            height="735px"
            visible
            onHiding={onCancel}
            showCloseButton
            title="Capital Allowance Schedule"
        >
            <TabPanel
                className="formcs-tabpanel"
                focusStateEnabled={false}
                showNavButtons
            >
                <Item title="Non-Hire-Purchase Assets" >
                    <div className="mt-1" >
                        <div className="fw-bold py-2">Additions during the Year</div>
                        <FinancialScheduleGrid
                            dataSource={data.nonHPDataSource.additionsDuringTheYear}
                        >
                            <Column
                                caption="Description"
                                dataField="descriptionEachAsset"
                                dataType="string"
                            />
                            <Column
                                caption="YA of Purchase"
                                dataField="yaOfPurchaseEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Cost (S$)"
                                dataField="costEachAsset"
                                format="#,##0.00"
                                dataType="number"
                                width="100px"
                            />
                            <Column
                                caption="No. of Years of Working Life B/F Tax"
                                dataField="noOfYearsOfWorkingLifeBFEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Tax Written Down Value B/F (S$)"
                                dataField="taxWrittenDownValueBFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Annual Allowance-AA (S$)"
                                dataField="annualAllowanceEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Tax Written Down Value C/F (S$)"
                                dataField="taxWrittenDownValueCFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                        </FinancialScheduleGrid>
                    </div>
                    <div className="mt-1" >
                        <div className="fw-bold py-2">Prior Year Additions</div>
                        <FinancialScheduleGrid
                            dataSource={data.nonHPDataSource.priorYearAdditions}
                        >
                            <Column
                                caption="Description"
                                dataField="descriptionEachAsset"
                                dataType="string"
                            />
                            <Column
                                caption="YA of Purchase"
                                dataField="yaOfPurchaseEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Cost (S$)"
                                dataField="costEachAsset"
                                format="#,##0.00"
                                dataType="number"
                                width="100px"
                            />
                            <Column
                                caption="No. of Years of Working Life B/F Tax"
                                dataField="noOfYearsOfWorkingLifeBFEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Tax Written Down Value B/F (S$)"
                                dataField="taxWrittenDownValueBFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Annual Allowance-AA (S$)"
                                dataField="annualAllowanceEachAsset"
                                format="#,##0.00" 
                                dataType="number"
                            />
                            <Column
                                caption="Tax Written Down Value C/F (S$)"
                                dataField="taxWrittenDownValueCFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                        </FinancialScheduleGrid>
                    </div>
                    <div className="mt-1" >
                        <div className="fw-bold py-2">Disposals during the Year</div>
                        <FinancialScheduleGrid
                            dataSource={data.nonHPDataSource.disposalsDuringTheYear}
                        >
                            <Column
                                caption="Description"
                                dataField="descriptionEachAsset"
                                dataType="string"
                            />
                            <Column
                                caption="YA of Purchase"
                                dataField="yaOfPurchaseEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Cost (S$)"
                                dataField="costEachAsset"
                                format="#,##0.00"
                                dataType="number"
                                width="100px"
                            />
                            <Column
                                caption="No. of Years of Working Life B/F Tax"
                                dataField="noOfYearsOfWorkingLifeBFEachAsset"
                                dataType="number"
                                alignment="center"
                            />
                            <Column
                                caption="Tax Written Down Value B/F (S$)"
                                dataField="taxWrittenDownValueBFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="YA of Disposal"
                                dataField="yaOfDisposalEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Sales Proceed (S$)"
                                dataField="salesProceedEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Balancing Allowance (BA) / Balancing Charge (BC) (S$)"
                                format="#,##0.00"
                                dataType="number"
                                calculateCellValue={calculateBalancingAllowanceOrBalancingChargeCellValue}
                            />
                        </FinancialScheduleGrid>
                    </div>
                </Item>
                <Item title="Hire-Purchase Assets">
                    <div className="mt-1" >
                        <div className="fw-bold py-2">Additions during the Year</div>
                        <FinancialScheduleGrid
                            dataSource={data.hpDataSource.additionsDuringTheYear}
                        >
                            <Column
                                caption="Description"
                                dataField="descriptionEachAsset"
                                dataType="string"
                            />
                            <Column
                                caption="YA of Purchase"
                                dataField="yaOfPurchaseEachAsset"
                                dataType="number"
                                alignment="center"
                            />
                            <Column
                                caption="Cost (S$)"
                                dataField="costEachAsset"
                                format="#,##0.00"
                                dataType="number"
                                width="100px"
                            />
                            <Column
                                caption="Tax Written Down Value B/F (S$)"
                                dataField="taxWrittenDownValueBFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Annual Allowance-AA (S$)"
                                dataField="annualAllowanceEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Tax Written Down Value C/F (S$)"
                                dataField="taxWrittenDownValueCFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Deposit/ Principal Paid During the Year (excluding Interest, including Down Payment) (S$)"
                                dataField="depositOrPrincipalExcludingInterestIncludingDownpaymentEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                        </FinancialScheduleGrid>
                    </div>
                    <div className="mt-1" >
                        <div className="fw-bold py-2">Prior Year Additions</div>
                        <FinancialScheduleGrid
                            dataSource={data.hpDataSource.priorYearAdditions}
                        >
                            <Column
                                caption="Description"
                                dataField="descriptionEachAsset"
                                dataType="string"
                            />
                            <Column
                                caption="YA of Purchase"
                                dataField="yaOfPurchaseEachAsset"
                                dataType="number"
                                alignment="center"
                            />
                            <Column
                                caption="Cost (S$)"
                                dataField="costEachAsset"
                                format="#,##0.00"
                                dataType="number"
                                width="100px"
                            />
                            <Column
                                caption="Tax Written Down Value B/F (S$)"
                                dataField="taxWrittenDownValueBFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Annual Allowance-AA (S$)"
                                dataField="annualAllowanceEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Tax Written Down Value C/F (S$)"
                                dataField="taxWrittenDownValueCFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Deposit/ Principal Paid During the Year (excluding Interest, including Down Payment) (S$)"
                                dataField="depositOrPrincipalExcludingInterestIncludingDownpaymentEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                        </FinancialScheduleGrid>
                    </div>

                    <div className="mt-1" >
                        <div className="fw-bold py-2">Disposals during the Year</div>
                        <FinancialScheduleGrid
                            dataSource={data.hpDataSource.disposalsDuringTheYear}
                        >
                            <Column
                                caption="Description"
                                dataField="descriptionEachAsset"
                                dataType="string"
                            />
                            <Column
                                caption="YA of Purchase"
                                dataField="yaOfPurchaseEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Cost (S$)"
                                dataField="costEachAsset"
                                format="#,##0.00"
                                dataType="number"
                                width="100px"
                            />
                            <Column
                                caption="Tax Written Down Value B/F (S$)"
                                dataField="taxWrittenDownValueBFEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="YA of Disposal"
                                dataField="yaOfDisposalEachAsset"
                                dataType="number"
                            />
                            <Column
                                caption="Sales Proceed (S$)"
                                dataField="salesProceedEachAsset"
                                format="#,##0.00"
                                dataType="number"
                            />
                            <Column
                                caption="Balancing Allowance (BA) / Balancing Charge (BC) (S$)"
                                format="#,##0.00"
                                dataType="number"
                                calculateCellValue={calculateBalancingAllowanceOrBalancingChargeCellValue}
                            />
                        </FinancialScheduleGrid>
                    </div>
                </Item>
            </TabPanel>
            <ToolbarItem
                toolbar="bottom"
                location="after"
            >
                <Button
                    text="Close"
                    onClick={onCancel}
                />
            </ToolbarItem>
        </StandardPopup >
    );
};

export default CapitalAllowanceSchedule;