import { useAccountBookData } from 'contexts/AccountBookDataContext';
import { PrimaryButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';

const AccountMappingCompletedDialog = (props) => {
    const { onCancel, startAnnualReturn, startFormCs } = props;
    const { canSubmitFormCS } = useAccountBookData();

    return (
        <StandardPopup
            title="Account Mapping Completed"
            onHiding={onCancel}
            showCloseButton
            visible
            showTitle
            width={canSubmitFormCS ? 800 : 400}
            height="auto"
        >
            <div className="row justify-content-around align-items-center mb-3">
                <div className={canSubmitFormCS ? 'col-6' : 'col-12'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src="/images/acra-logo.png" style={{ height: '80px', objectFit: 'contain' }} />
                    <PrimaryButton
                        className="col-8 mt-3"
                        text="Start Annual Return Filing"
                        onClick={() => {
                            startAnnualReturn();
                            onCancel();
                        }}
                    />
                </div>
                {canSubmitFormCS &&
                    <div className="col-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src="/images/iras-logo.png" style={{ height: '80px', objectFit: 'contain' }} />
                        <PrimaryButton
                            className="col-8 mt-3"
                            text="Start Form C-S Filing"
                            onClick={() => {
                                startFormCs();
                                onCancel();
                            }}
                        />
                    </div>
                }
            </div>
        </StandardPopup>
    );
};

export default AccountMappingCompletedDialog;