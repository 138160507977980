import CustomStore from 'devextreme/data/custom_store';
import { Button as NormalButton } from 'devextreme-react/button';
import { Column, Paging, Selection, Toolbar } from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import { ToolbarItem } from 'devextreme-react/popup';
import { SelectBox } from 'devextreme-react/select-box';
import { Scrolling } from 'devextreme-react/tree-list';
import { useState, useMemo, useRef } from 'react';
import * as LookupApi from 'api/lookupApi';
import { PrimaryButton } from 'components/StandardButton';
import StandardDataGrid from 'components/StandardDataGrid';
import StandardPopup from 'components/StandardPopup';
import StandardTreeList from 'components/StandardTreeList';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ErrorMessage from 'utils/errorMessage';
import * as PageUtils from 'utils/pageUtils';
import * as Toast from 'utils/toast';
import * as AccountMappingApi from '../api/accountMappingApi';
import OnPremiseAccountMappingImportDialog from './OnPremiseAccountMappingImportDialog';
import '../AccountMappingMaintenance.scss';

const AccountMappingImportDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    const { accountBookData } = useAccountBookData();
    const accountBookDataId = accountBookData && accountBookData.accountBookDataId;

    const [step, setStep] = useState(1);
    const [selectedAccountBookData, setSelectedAccountBookData] = useState(null);
    const [accountMappingImportData, setAccountMappingImportData] = useState(null);
    const [selectedOnPremiseAccountMapping, setSelectedOnPremiseAccountMapping] = useState(null);

    const [showImportOnPremiseAccountMapping, setShowImportOnPremiseAccountMapping] = useState(false);

    const [dataChanges, setDataChanges] = useState([]);
    const accountMappingDataGridRef = useRef();
    const chartOfAccountTreeListRef = useRef();

    const [accountMappingData, setAccountMappingData] = useState(null);
    const [accountMappingViewData, setAccountMappingViewData] = useState(null);

    const accountBookDataSource = useMemo(() => new CustomStore({
        key: 'accountBookDataId',
        loadMode: 'raw',
        load: function () {
            return LookupApi.getAccountBookData()
                .then(data => {
                    return data.filter(account => account.accountBookDataId !== accountBookDataId);
                });
        }
    }), [accountBookDataId]);

    const onPremiseAccountMappingDataSource = useMemo(() => new CustomStore({
        key: 'mappingMastId',
        loadMode: 'raw',
        load: function () {
            return LookupApi.getOnPremiseAccountMappingData(accountBookDataId);
        }
    }), [accountMappingImportData]);

    function getAccountMappingImportData(accountBookDataId) {
        PageUtils.blockPage();
        AccountMappingApi.getAccountMapping(accountBookDataId)
            .then(result => {
                PageUtils.unblockPage();
                setAccountMappingImportData(generateAccountMappingViewData(result.accountMappingData));
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function changeAccountBookData(e) {
        if (e.value) {
            setStep(1);
            setSelectedOnPremiseAccountMapping(null);
            setSelectedAccountBookData(e.value);
            getAccountMappingImportData(e.value.accountBookDataId);
        }
    }

    function getOnPremiseAccountMapping(accountMappingMasterId) {
        PageUtils.blockPage();
        AccountMappingApi.getOnPremiseAccountMapping(accountBookDataId, accountMappingMasterId)
            .then((result) => {
                PageUtils.unblockPage();
                setAccountMappingImportData(generateAccountMappingViewData(result));
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function changeOnPremiseAccountMappingData(onPremiseAccountMapping) {
        if (onPremiseAccountMapping) {
            setStep(1);
            setSelectedAccountBookData(null);
            setSelectedOnPremiseAccountMapping(onPremiseAccountMapping);
            getOnPremiseAccountMapping(onPremiseAccountMapping.mappingMastId);
        }
    }

    function generateAccountMappingViewData(accountMappingData) {
        //add account description and pde description
        const list = [];
        accountMappingData.forEach(accountMapping => {
            const account = initialData.chartOfAccountData.find(account => account.accNo === accountMapping.accNo);
            const pde = initialData.pdeList.find(pde => pde.dataId === accountMapping.pde);

            if (account) {
                list.push({
                    ...accountMapping,
                    accDesc: account && account.description,
                    pdeDesc: pde && pde.description
                });
            }
        });
        return list;
    }

    function getDataChanges(accountMappingData) {
        const dataChanges = [];
        accountMappingData.forEach(accountMapping => {
            const dataChange = { accNo: accountMapping.accNo, originalPde: null, currentPde: accountMapping.pde, status: null };
            const existingAccountMapping = initialData.accountMappingData.find(x => x.accNo === accountMapping.accNo);
            if (existingAccountMapping) {
                dataChange.originalPde = existingAccountMapping.pde;
                if (existingAccountMapping.pde !== accountMapping.pde || existingAccountMapping.liquidity !== accountMapping.liquidity)
                    dataChange.status = 'Modified';
                else
                    dataChange.status = 'No Action';
            } else if (accountMapping.pde) {
                dataChange.originalPde = null;
                dataChange.status = 'Added';
            } else {
                dataChange.status = 'No Action';
            }

            if (dataChange.status !== 'No Action')
                dataChanges.push(dataChange);
        });

        return dataChanges;
    }

    function mergeAccountMapping() {
        const importedData = accountMappingDataGridRef.current.instance.getSelectedRowsData();
        const existingData = initialData.accountMappingData;

        const mergedData = existingData.map(x => ({ accNo: x.accNo, pde: x.pde, liquidity: x.liquidity }));

        importedData.forEach(accountMapping => {
            const index = mergedData.findIndex(account => account.accNo === accountMapping.accNo);
            if (index >= 0) {
                mergedData[index] = { ...mergedData[index], pde: accountMapping.pde, liquidity: accountMapping.liquidity };
            } else {
                mergedData.push({ ...accountMapping });
            }
        });

        const viewData = [...mergedData];
        viewData.sort((a, b) => a.accNo.localeCompare(b.accNo));

        initialData.chartOfAccountData.forEach(account => {
            if (!account.isHeader) {
                const viewDataItem = viewData.find(x => x.accNo === account.accNo);
                if (!viewDataItem)
                    viewData.push(account);
                else
                    viewDataItem.parentAccNo = account.parentAccNo;
            }
        });

        setAccountMappingData(mergedData);
        setAccountMappingViewData(viewData);
        setDataChanges(getDataChanges(mergedData));
    }

    function getAutoAccountMapping() {
        PageUtils.blockPage();
        AccountMappingApi.generateAutoAccountMapping(accountBookDataId)
            .then((data) => {
                PageUtils.unblockPage();
                setSelectedAccountBookData(null);
                setSelectedOnPremiseAccountMapping(null);
                setAccountMappingImportData(generateAccountMappingViewData(data));
                setStep(1);
                Toast.showSuccessMessage('Default Account Mapping is applied.');
            })
            .catch(error => {
                PageUtils.unblockPage();
                Toast.showErrorMessage(ErrorMessage.fetchError(error));
            });
    }

    function applyFilterToShowChangesOnly(component) {
        const filterExpr = [];
        dataChanges.forEach(x => {
            filterExpr.push(['accNo', '=', x.accNo]);
            filterExpr.push('or');
        });
        component.filter(filterExpr);
    }

    return (
        <>
            <StandardPopup
                fullScreen
                visible
                onHiding={onCancel}
                showCloseButton
                title="Import Account Mapping"
            >
                <div className="row mb-4">
                    <div className="col-12 col-sm-3 mb-2">
                        <div className="d-flex justify-content-start fs-4 fw-bold">
                            {step === 1 && 'Select Account Mapping'}
                            {step === 2 && 'Review Changes'}
                        </div>
                    </div>

                    {step === 1 &&
                        <>
                            <div className="col-12 col-sm-5 mb-2">
                                <SelectBox
                                    dataSource={accountBookDataSource}
                                    value={selectedAccountBookData}
                                    displayExpr={itemData => itemData && `YA ${itemData.yearOfAssessment} - ${itemData.companyName}`}
                                    onValueChanged={changeAccountBookData}
                                    disabled={step > 1}
                                    width="450px"
                                    placeholder="Please select an Account Book."
                                />
                            </div>
                            <div className="col-6 col-sm-2 mb-2" >
                                <NormalButton
                                    text="Import from On-Premise"
                                    onClick={() => setShowImportOnPremiseAccountMapping(true)}
                                    disabled={step > 1}
                                    visible={initialData.hasOnPremiseAccountMapping}
                                    width="200px"
                                />
                            </div>
                            <div className="col-6 col-sm-2" >
                                <NormalButton
                                    text="Auto Mapping"
                                    onClick={getAutoAccountMapping}
                                    disabled={step > 1}
                                    width="200px"
                                />
                            </div>
                        </>

                    }
                    {step === 2 && (dataChanges && dataChanges.length > 0) &&
                        <div className="col-9 d-flex justify-content-end">
                            <CheckBox
                                className="fs-8"
                                text="Show Changes Only"
                                defaultValue
                                onValueChanged={e => {
                                    if (e.value) {
                                        applyFilterToShowChangesOnly(chartOfAccountTreeListRef.current.instance);
                                    } else {
                                        chartOfAccountTreeListRef.current.instance.clearFilter();
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
                <div>
                    {accountMappingImportData &&
                        <>
                            {step === 1 &&
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <StandardDataGrid
                                            ref={accountMappingDataGridRef}
                                            dataSource={accountMappingImportData}
                                            keyExpr="accNo"
                                            showColumnLines={false}
                                            height="calc(100vh -  240px)"
                                        >
                                            <Selection
                                                mode="multiple"
                                                showCheckBoxesMode="always"
                                            />
                                            <Paging enabled={false} />
                                            <Toolbar visible={false} />
                                            <Column caption="Account Number" dataField="accNo" dataType="string" sortOrder="asc" />
                                            <Column caption="Account Description" dataField="accDesc" dataType="string" />
                                            <Column
                                                caption="PDE"
                                                dataField="pdeDesc"
                                                dataType="string"
                                                cellRender={e => {
                                                    return (
                                                        <>
                                                            {e.data.liquidity &&
                                                                <div style={{ lineHeight: '13px', marginRight: '4px' }} className="badge bg-dark text-light fw-normal align-self-center">
                                                                    {e.data.liquidity}
                                                                </div>
                                                            }
                                                            {e.data.pdeDesc}
                                                        </>
                                                    );
                                                }}
                                            />
                                        </StandardDataGrid>
                                    </div>
                                </div>
                            }
                            {step === 2 &&
                                <>
                                    {(dataChanges && dataChanges.length > 0) ?
                                        <div className="row">
                                            <div className="col-12 d-flex justify-content-center">
                                                <StandardTreeList
                                                    dataSource={accountMappingViewData}
                                                    keyExpr="accNo"
                                                    parentIdExpr="parentAccNo"
                                                    showColumnLines={false}
                                                    autoExpandAll
                                                    height="calc(100vh - 240px)"
                                                    onInitialized={e => applyFilterToShowChangesOnly(e.component)}
                                                    onRowPrepared={e => {
                                                        if (e.rowType === 'data') {
                                                            const dataChange = dataChanges.find(x => x.accNo === e.data.accNo);
                                                            if (dataChange) e.rowElement.classList.add('account-mapping-modified-row');
                                                        }
                                                    }}
                                                    ref={chartOfAccountTreeListRef}
                                                >
                                                    <Scrolling mode="virtual" />
                                                    <Paging enabled={false} />
                                                    <Toolbar visible={false} />
                                                    <Column width="150px" caption="Account Number" dataField="accNo" dataType="string" sortOrder="asc" allowSorting />
                                                    <Column
                                                        caption="Account Description"
                                                        width="600px"
                                                        calculateDisplayValue={e => {
                                                            const account = initialData.chartOfAccountData.find(x => x.accNo === e.accNo);
                                                            return account && account.description;
                                                        }}
                                                    />
                                                    <Column
                                                        caption="PDE"
                                                        width="600px"
                                                        cellRender={e => {
                                                            const pde = initialData.pdeList.find(x => x.dataId === e.data.pde);
                                                            return (
                                                                <>
                                                                    {pde && e.data.liquidity &&
                                                                        <div style={{ lineHeight: '13px', marginRight: '4px' }} className="badge bg-dark text-light fw-normal align-self-center">
                                                                            {e.data.liquidity}
                                                                        </div>
                                                                    }
                                                                    {pde && pde.description}
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                    <Column
                                                        caption="Status"
                                                        cellRender={e => {
                                                            const dataChange = dataChanges.find(x => x.accNo === e.data.accNo);
                                                            return (
                                                                <>
                                                                    {dataChange && dataChange.status === 'Added' && <div style={{ color: 'green' }}>{dataChange.status}</div>}
                                                                    {dataChange && dataChange.status === 'Modified' && <div style={{ color: 'blue' }}>{dataChange.status}</div>}
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                </StandardTreeList>
                                            </div>
                                        </div>
                                        :
                                        <div className="mt-5 d-flex justify-content-center h2">No changes were found.</div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                {(accountMappingImportData && step === 1) &&
                    <ToolbarItem
                        toolbar="bottom"
                        location="center"
                    >
                        <PrimaryButton
                            text="Merge Account Mapping"
                            onClick={() => {
                                setStep(step + 1);
                                mergeAccountMapping();
                            }}
                        />
                    </ToolbarItem>
                }
                {step === 2 &&
                    <ToolbarItem
                        toolbar="bottom"
                        location="center"
                    >
                        <PrimaryButton
                            disabled={dataChanges.length === 0}
                            text="Save"
                            onClick={() => onSubmit(accountMappingData)}
                        />
                    </ToolbarItem>
                }
                {step === 2 &&
                    <ToolbarItem
                        toolbar="bottom"
                        location="before"
                    >
                        <NormalButton
                            text="Back"
                            onClick={() => setStep(step - 1)}
                        />
                    </ToolbarItem>
                }
                <ToolbarItem
                    toolbar="bottom"
                    location="center"
                >
                    <NormalButton
                        text="Cancel"
                        onClick={onCancel}
                    />
                </ToolbarItem>
            </StandardPopup>
            {showImportOnPremiseAccountMapping &&
                <OnPremiseAccountMappingImportDialog
                    onPremiseAccountMappingDataSource={onPremiseAccountMappingDataSource}
                    changeOnPremiseAccountMappingData={changeOnPremiseAccountMappingData}
                    selectedOnPremiseAccountMapping={selectedOnPremiseAccountMapping}
                    onCancel={() => setShowImportOnPremiseAccountMapping(false)}
                />
            }
        </>
    );
};

export default AccountMappingImportDialog;