import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ApplicationUtils from 'utils/applicationUtils';

const InformationBanner = () => {
    const { accountBookData } = useAccountBookData();
    const yearOfAssessment = accountBookData && accountBookData.yearOfAssessment;

    const currentYA = ApplicationUtils.getYearOfAssessment();

    if (currentYA > yearOfAssessment) {
        return <div className="alert alert-danger">Submission for Annual Return {yearOfAssessment - 1} is due.</div>;
    }
};

export default InformationBanner;