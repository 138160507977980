import {
    Page,
    StyleSheet,
    Text,
    View
} from '@react-pdf/renderer';

const TaxComputationPdfView = (props) => {
    const { data } = props;
    let i = 0;

    const styles = StyleSheet.create({
        page: {
            padding: 40
        },
        header: {
            fontSize: 15,
            fontWeight: 1000,
            marginBottom: 10,
            display: 'table',
            width: 'auto',
            textDecoration: 'underline',
            textAlign: 'center'
        },
        normalText: {
            fontSize: 8,
            margin: 3,
            paddingBottom: 5
        },
        table: {
            display: 'table',
            width: 'auto',
            borderStyle: 'solid',
            borderWidth: 1
        },
        tableHeader: {
            backgroundColor: '#21a84a',
            color: 'white',
            fontWeight: 1000
        },
        tableHeaderText: {
            fontSize: 10,
            margin: 5,
            fontWeight: 1000
        }
    });

    const viewData = (() => {
        const list = [
            { key: 'profitLossBeforeTaxation' },
            {},
            { tag: 'Less :', description: 'Separate Source Income' },
            { key: 'sgIntDisc' },
            { key: 'c1_GrossRent' },
            { key: 'sgOtherI' },
            { key: 'sepSrcIncome' },
            {},
            { tag: 'Less :', description: 'Non-Taxable Income' },
            { key: 'oneTierTaxDividendIncome', description: 'Dividend Income - One-Tier/Tax Exempt' },
            { key: 'otherNonTaxableIncome' },
            { key: 'receiptNotTxAmt', description: 'Total Non-Taxable Income' },
            {},
            { tag: 'Add :', description: 'Non-Tax Deductible Income' },
            { key: 'amortisationExpense' },
            { key: 'depreciationExpense' },
            { key: 'donations' },
            { key: 'commissionExpRentalIncome' },
            { key: 'insuranceExpRentalIncome' },
            { key: 'interestExpRentalIncome' },
            { key: 'propertyTaxExpRentalIncome' },
            { key: 'repairMaintenanceExpRentalIncome' },
            { key: 'otherExpRentalIncome' },
            { key: 'fixedAssetsExpdOff' },
            { key: 'netGainsOrLossesOnDisposalOfPPE' },
            { key: 'netGainsOrLossesOnOtherItems' },
            { key: 'otherPrivateOrCapitalExp' },
            { key: 'penaltiesOrFine' },
            { key: 'repairsMaintenanceForPrivateVehicles' },
            { key: 'upkeepPrivateVehicles' },
            { key: 'medicalExpDisallowed' },
            { key: 'c1_NTDeductibleExp' },
            { key: 'adjPLBefDed' },
            {},
            {}, // Add 2 line to push it to new page
            {},
            { tag: 'Less:', key: 'renoWorksExpS14Q' },
            { key: 'c1_EnhancedEISDed', description: 'Enhanced Deductions under Enterprise Innovation Scheme (EIS)' },
            { key: 'c1_FurtherDed' },
            { key: 'sgAdjPLAft' },
            {},
            { tag: 'Add:', key: 'c1_BC' },
            { tag: 'Less:', key: 'unutilCABFNorm' },
            { key: 'currentYearCapitalAllowancesNonHPAndHP' },
            { key: 'balancingAllowancesNonHPandHP' },
            { tag: 'Less', key: 'unutilLossBFNorm' },
            { key: 'adjustedProfitOrLossAfterCapitalAllowanceAndUnutilisedLossesBF' },
            {},
            { tag: 'Add:', description: 'Separate Source Income' },
            { key: 'sgRent' },
            { key: 'sgIntDisc', description: 'Interest Income' },
            { key: 'sgOtherI', description: 'Other Types of Income - Taxable Income' },
            { key: 'totSgFI' },
            { tag: 'Less:', key: 'unutilDonationBFNorm' },
            { key: 'cyDonation' },
            { key: 'chargeableIncomeBefTaxExemptAmt' },
            { tag: 'Less:', key: 'taxExemptAmount' },
            { key: 'chargeableIncomeAftTaxExemptAmt' },
            {},
            { key: 'taxPayable' },
            { tag: 'Less:', key: 'taxRebate' },
            { key: 'netTaxPayable', description: 'Net Tax Payable' },
            {},
            { key: 'unutilCACFNorm' },
            { key: 'unutilLossCFNorm' },
            { key: 'unutilDonationCFNorm' }
        ];

        const rawData = data.generatedDraft.dataTCSch;

        list.forEach(record => {
            if (record.key) {
                if ([
                    'sgIntDisc',
                    'c1_GrossRent',
                    'sgOtherI',
                    'oneTierTaxDividendIncome',
                    'otherNonTaxableIncome',
                    'amortisationExpense',
                    'depreciationExpense',
                    'donations',
                    'commissionExpRentalIncome',
                    'insuranceExpRentalIncome',
                    'interestExpRentalIncome',
                    'propertyTaxExpRentalIncome',
                    'repairMaintenanceExpRentalIncome',
                    'otherExpRentalIncome',
                    'fixedAssetsExpdOff',
                    'netGainsOrLossesOnDisposalOfPPE',
                    'netGainsOrLossesOnOtherItems',
                    'otherPrivateOrCapitalExp',
                    'penaltiesOrFine',
                    'repairsMaintenanceForPrivateVehicles',
                    'upkeepPrivateVehicles',
                    'medicalExpDisallowed'
                ].includes(record.key) 
                ) {
                    record.amount1 = rawData[record.key].toString();
                } else {
                    record.amount2 = rawData[record.key].toString();
                }

                if (record.description === 'Other Types of Income - Taxable Income' || record.description === 'Interest Income') {
                    record.amount1 = null;
                    record.amount2 = rawData[record.key].toString();
                }

                if (!record.description) {
                    const pde = data.pdeList.find(x => x.dataId === record.key);
                    record.description = (pde && pde.description);
                }
            }
        });

        return list;
    })();

    const generateRow = (data) => {
        return (
            <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                <View style={[styles.normalText, { width: 30 }]}>
                    <Text>{data.tag ?? null} </Text>
                </View>
                <View style={[
                    styles.normalText,
                    { flex: 1, alignItems: 'flex-start' },
                    (
                        [
                            'Separate Source Income',
                            'Non-Taxable Income',
                            'Non-Tax Deductible Income'
                        ].includes(data.description) && !data.amount1 && !data.amount2
                    ) ? { textDecoration: 'underline' } : null
                ]}
                >
                    <Text>{data.description}</Text>
                </View>
                <View style={[
                    styles.normalText,
                    { width: 60, alignItems: 'flex-end', marginRight: 20 },
                    (
                        [
                            'otherNonTaxableIncome',
                            'medicalExpDisallowed'
                        ].includes(data.key) || data.description === 'Other Taxable Income'
                    ) ? { borderBottom: '1px solid underline' } : null
                ]}
                >
                    <Text>{data.amount1 ? formatValue(Number(data.amount1)) : null}</Text>
                </View>
                <View style={[
                    styles.normalText,
                    { width: 60, alignItems: 'flex-end' },
                    (
                        [
                            'netTaxPayable',
                            'chargeableIncomeAftTaxExemptAmt'
                        ].includes(data.key)
                    ) ? { borderBottom: '2px double black' } : null,
                    (
                        [
                            'c1_NTDeductibleExp',
                            'c1_FurtherDed',
                            'unutilLossBFNorm',
                            'cyDonation',
                            'taxExemptAmount',
                            'taxRebate'
                        ].includes(data.key) || data.description === 'Other Types of Income - Taxable Income'
                    ) ? { borderBottom: '1px solid underline' } : null
                ]}
                >
                    <Text>{data.amount2 ? formatValue(Number(data.amount2)) : null}</Text>
                </View>
            </View>
        );
    };

    const generate_c1_EnhancedEISDedRow = (data) => {
        return (
            <>
                <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30, marginBottom: 1 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', marginBottom: 1 }]}>
                        <Text>{data.description}</Text>
                    </View>
                    <View style={[styles.normalText, { width: 60, alignItems: 'flex-end', marginRight: 20, marginBottom: 1 }]}>
                        <Text>{data.amount1 ? formatValue(Number(data.amount1)) : null}</Text>
                    </View>
                    <View style={[styles.normalText, { width: 60, alignItems: 'flex-end', marginBottom: 1 }]}>
                        <Text>{data.amount2 ? formatValue(Number(data.amount2)) : null}</Text>
                    </View>
                </View>
                <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3 }]}>
                        <Text>{'\u2022' + '  Training;'}</Text>
                    </View>
                </View>
                <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3 }]}>
                        <Text>{'\u2022' + '  Innovation Projects carried out with Partner Institutions;'}</Text>
                    </View>
                </View>
                <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3 }]}>
                        <Text>{'\u2022' + '  Licensing of Intellectual Property Rights;'}</Text>
                    </View>
                </View>
                <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3 }]}>
                        <Text>{'\u2022' + '  Registration of Intellectual Property;'}</Text>
                    </View>
                </View>
                <View key={++i} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.normalText, { flex: 1, alignItems: 'flex-start', paddingLeft: 20, margin: -2, marginHorizontal: 3 }]}>
                        <Text>{'\u2022' + '  Qualifying R&D undertaken in Singapore;'}</Text>
                    </View>
                </View>
            </>
        );
    };

    const formatValue = (value) => {
        if (typeof value === 'number') {
            if (value < 0) {
                return `(${Math.abs(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')})`;
            } else {
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            }
        }
        return value;
    };

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.container}>
                <View style={styles.header}>
                    <Text>Tax Computation</Text>
                </View>
            </View>
            <View style={styles.table}>
                <View style={[styles.tableHeader, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }]}>
                    <View style={[styles.normalText, { width: 30 }]}>
                        <Text></Text>
                    </View>
                    <View style={[styles.tableHeaderText, { flex: 1, alignItems: 'flex-start' }]}>
                        <Text>Description</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: 60, alignItems: 'flex-end', marginRight: 20 }]}>
                        <Text>S$</Text>
                    </View>
                    <View style={[styles.tableHeaderText, { width: 60, alignItems: 'flex-end' }]}>
                        <Text>S$</Text>
                    </View>
                </View>
                {viewData.map(data => {
                    if (data.key === 'c1_EnhancedEISDed') return generate_c1_EnhancedEISDedRow(data);

                    return generateRow(data);
                })}
            </View>
        </Page>
    );
};

export default TaxComputationPdfView;