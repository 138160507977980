import { formatDate } from 'devextreme/localization';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as ApplicationUtils from 'utils/applicationUtils';

const InformationBanner = () => {
    const { accountBookData } = useAccountBookData();
    const yearOfAssessment = accountBookData && accountBookData.yearOfAssessment;

    const currentYA = ApplicationUtils.getYearOfAssessment();

    if (currentYA > yearOfAssessment) {
        return <div className="alert alert-danger">Form C-S Submission for YA {yearOfAssessment}  is due.</div>;
    } else if (currentYA < yearOfAssessment) {
        return <div className="alert alert-danger">Form C-S Submission for YA {yearOfAssessment} is not yet available. The availability date will be announced soon.</div>;
    } else {
        const currentDateTime = new Date();
        const startDate = new Date(window.APP_SETTINGS_FormCsFilingStartDate);
        const endDate = new Date(window.APP_SETTINGS_FormCsFilingEndDate);

        if (startDate > currentDateTime || !window.APP_SETTINGS_FormCsFilingStartDate) {
            return <div className="alert alert-danger">Form C-S Submission for YA {currentYA} is not yet available. The availability date will be announced soon.</div>;
        } else if (endDate < currentDateTime) {
            return <div className="alert alert-danger">Submission for Form C-S for YA {currentYA} is due on {formatDate(endDate, 'yyyy-MM-dd')}.</div>;
        } else {
            return <div className="alert alert-info">Submission for Form C-S for YA {currentYA} is available from {formatDate(startDate, 'yyyy-MM-dd')} until {formatDate(endDate, 'yyyy-MM-dd')}.</div>;
        }
    }
};

export default InformationBanner;