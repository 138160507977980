import React, { useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAccountBookData } from 'contexts/AccountBookDataContext';
import * as AccountBookDataStatusConstant from 'data/accountBookDataStatusConstant';
import { ErrorBoundary } from 'layouts';
import routes from './app-routes';
import { SideNavInnerToolbar as SideNavBarLayout } from './layouts';

const Content = () => {
    const { accountBookData, isPriorYA } = useAccountBookData();
    function generateRoutes() {
        return routes.map(({ name, path, component }) => {

            if (isPriorYA) {
                if (!accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedFormCS) &&
                    ['FixedAssetMaintenancePage', 'RenovationExpenseMaintenancePage'].includes(name))
                    return null;
                else if (!accountBookData.stepList.some(x => x === AccountBookDataStatusConstant.CompletedAnnualReturn) &&
                    ['FinancialStatementPage'].includes(name))
                    return null;
            }

            const Component = component;
            return (
                <Route
                    key={path}
                    path={path}
                    element={<Component />}
                />
            );
        });
    }

    const generatedRoutes = useMemo(generateRoutes, []);

    return (
        <SideNavBarLayout>
            <ErrorBoundary>
                <Routes>
                    {generatedRoutes}
                    <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                    />
                </Routes>
            </ErrorBoundary>
        </SideNavBarLayout>
    );
};

export default Content;
