import LoadPanel from 'devextreme-react/load-panel';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import * as ApplicationApi from 'api/applicationApi';
import * as ApplicationUtils from 'utils/applicationUtils';

function AccountBookDataProvider({ children }) {
    const navigate = useNavigate();
    const [accountBookData, setAccountBookData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const isPriorYA = accountBookData?.yearOfAssessment < ApplicationUtils.getYearOfAssessment();

    const canSubmitFormCS = useMemo(() => {
        if (accountBookData) {
            if (accountBookData.yearOfAssessment > window.APP_SETTINGS_YearOfAssessment) {
                return false;
            } else if (accountBookData.yearOfAssessment < window.APP_SETTINGS_YearOfAssessment) {
                return false;
            } else {
                if (!window.APP_SETTINGS_FormCsFilingStartDate) return false;

                const currentDateTime = new Date();
                const startDate = new Date(window.APP_SETTINGS_FormCsFilingStartDate);
                const endDate = new Date(window.APP_SETTINGS_FormCsFilingEndDate);

                return currentDateTime > startDate && currentDateTime < endDate;
            }
        }

        return false;
    }, [accountBookData]);

    useEffect(() => {
        let accountBookDataId = null;

        if (accountBookDataId === null) {
            // Open in New Page
            const urlParams = new URLSearchParams(window.location.search);
            accountBookDataId = urlParams.get('accountBookDataId');

            const clone = new URL(window.location.href);
            clone.searchParams.delete('accountBookDataId');
            window.history.replaceState({}, null, clone.href);
        }

        if (accountBookDataId === null) {
            // Refresh Page
            const currentAccountBookData = sessionStorage.getItem('accountBookData');
            const accountBookData = JSON.parse(currentAccountBookData);
            accountBookDataId = accountBookData?.accountBookDataId;
        }

        if (accountBookDataId) {
            ApplicationApi.getAccountBookData(accountBookDataId)
                .then(data => {
                    setAccountBookData(data);
                    setIsLoading(false);
                    sessionStorage.setItem('accountBookData', JSON.stringify(data));
                });
        } else {
            setIsLoading(false);
            navigate('/');
        }
    }, []);

    const refreshAccountBookData = useCallback(() => {
        ApplicationApi.getAccountBookData(accountBookData?.accountBookDataId)
            .then(data => {
                setAccountBookData(data);
                sessionStorage.setItem('accountBookData', JSON.stringify(data));
            });
    }, [accountBookData]);

    return (
        <AccountBookDataContext.Provider value={{ accountBookData, setAccountBookData, refreshAccountBookData, isPriorYA, canSubmitFormCS }}>
            {isLoading && <LoadPanel visible />}
            {!isLoading && children}
        </AccountBookDataContext.Provider>
    );
}
const AccountBookDataContext = createContext({});
const useAccountBookData = () => useContext(AccountBookDataContext);
// eslint-disable-next-line react/no-multi-comp
const AccountBookDataConsumer = props => <AccountBookDataContext.Consumer {...props} />;

export { AccountBookDataContext, AccountBookDataProvider, AccountBookDataConsumer, useAccountBookData };