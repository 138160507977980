/* eslint-disable react/no-multi-comp */
import { useMemo } from 'react';
import ReportGrid from '../components/ReportGrid';

const TaxComputationView = (props) => {
    const { data } = props;

    const viewData = useMemo(() => {
        const list = [
            { key: 'profitLossBeforeTaxation' },
            {},
            { tag: 'Less :', description: 'Separate Source Income' },
            { key: 'sgIntDisc' },
            { key: 'c1_GrossRent' },
            { key: 'sgOtherI' },
            { key: 'sepSrcIncome' },
            {},
            { tag: 'Less :', description: 'Non-Taxable Income' },
            { key: 'oneTierTaxDividendIncome', description: 'Dividend Income - One-Tier/Tax Exempt' },
            { key: 'otherNonTaxableIncome' },
            { key: 'receiptNotTxAmt', description: 'Total Non-Taxable Income' },
            {},
            { tag: 'Add :', description: 'Non-Tax Deductible Income' },
            { key: 'amortisationExpense' },
            { key: 'depreciationExpense' },
            { key: 'donations' },
            { key: 'commissionExpRentalIncome' },
            { key: 'insuranceExpRentalIncome' },
            { key: 'interestExpRentalIncome' },
            { key: 'propertyTaxExpRentalIncome' },
            { key: 'repairMaintenanceExpRentalIncome' },
            { key: 'otherExpRentalIncome' },
            { key: 'fixedAssetsExpdOff' },
            { key: 'netGainsOrLossesOnDisposalOfPPE' },
            { key: 'netGainsOrLossesOnOtherItems' },
            { key: 'otherPrivateOrCapitalExp' },
            { key: 'penaltiesOrFine' },
            { key: 'repairsMaintenanceForPrivateVehicles' },
            { key: 'upkeepPrivateVehicles' },
            { key: 'medicalExpDisallowed' },
            { key: 'c1_NTDeductibleExp' },
            { key: 'adjPLBefDed' },
            {},
            { tag: 'Less:', key: 'renoWorksExpS14Q' },
            {
                key: 'c1_EnhancedEISDed',
                description: 'Enhanced Deductions under Enterprise Innovation Scheme (EIS)',
                isEditable: true,
                component: () => (
                    <>
                        Enhanced Deductions under Enterprise Innovation Scheme (EIS) for:
                        <ul className="mt-2 mb-0">
                            <li>Training;</li>
                            <li>Innovation Projects carried out with Partner Institutions;</li>
                            <li>Licensing of Intellectual Property Rights;</li>
                            <li>Registration of Intellectual Property;</li>
                            <li>Qualifying R&D undertaken in Singapore;</li>
                        </ul>
                    </>
                )
            },
            { key: 'c1_FurtherDed', isEditable: true },
            { key: 'sgAdjPLAft' },
            {},
            { tag: 'Add:', key: 'c1_BC' },
            { tag: 'Less:', key: 'unutilCABFNorm', isEditable: true },
            { key: 'currentYearCapitalAllowancesNonHPAndHP' },
            { key: 'balancingAllowancesNonHPandHP' },
            { tag: 'Less', key: 'unutilLossBFNorm', isEditable: true },
            { key: 'adjustedProfitOrLossAfterCapitalAllowanceAndUnutilisedLossesBF' },
            {},
            { tag: 'Add:', description: 'Separate Source Income' },
            { key: 'sgRent' },
            { key: 'sgIntDisc', description: 'Interest Income' },
            { key: 'sgOtherI', description: 'Other Types of Income - Taxable Income' },
            { key: 'totSgFI' },
            { tag: 'Less:', key: 'unutilDonationBFNorm', isEditable: true },
            { key: 'cyDonation' },
            { key: 'chargeableIncomeBefTaxExemptAmt' },
            { tag: 'Less:', key: 'taxExemptAmount' },
            { key: 'chargeableIncomeAftTaxExemptAmt' },
            {},
            { key: 'taxPayable' },
            { tag: 'Less:', key: 'taxRebate' },
            { key: 'netTaxPayable', description: 'Net Tax Payable' },
            {},
            { key: 'unutilCACFNorm' },
            { key: 'unutilLossCFNorm' },
            { key: 'unutilDonationCFNorm' }
        ];

        const rawData = data.generatedDraft.dataTCSch;

        list.forEach(record => {
            if (record.key) {
                if ([
                    'sgIntDisc',
                    'c1_GrossRent',
                    'sgOtherI',
                    'oneTierTaxDividendIncome',
                    'otherNonTaxableIncome',
                    'amortisationExpense',
                    'depreciationExpense',
                    'donations',
                    'commissionExpRentalIncome',
                    'insuranceExpRentalIncome',
                    'interestExpRentalIncome',
                    'propertyTaxExpRentalIncome',
                    'repairMaintenanceExpRentalIncome',
                    'otherExpRentalIncome',
                    'fixedAssetsExpdOff',
                    'netGainsOrLossesOnDisposalOfPPE',
                    'netGainsOrLossesOnOtherItems',
                    'otherPrivateOrCapitalExp',
                    'penaltiesOrFine',
                    'repairsMaintenanceForPrivateVehicles',
                    'upkeepPrivateVehicles',
                    'medicalExpDisallowed'
                ].includes(record.key)
                ) {
                    record.amount1 = rawData[record.key];
                } else {
                    record.amount2 = rawData[record.key];
                }

                if (record.description === 'Other Types of Income - Taxable Income' || record.description === 'Interest Income') {
                    record.amount1 = null;
                    record.amount2 = rawData[record.key];
                }
            }

            if (!record.description) {
                const pde = data.pdeList.find(x => x.dataId === record.key);
                record.description = (pde && pde.description);
            }
        });

        return list;
    }, [data]);

    return (
        <ReportGrid
            dataSource={viewData}
            onCellPrepared={(e) => {
                if (e.rowType === 'data') {
                    if (e.column.name === 'amount1') {
                        if ([
                            'otherNonTaxableIncome',
                            'medicalExpDisallowed'
                        ].includes(e.row.data.key) || e.row.data.description === 'Other Taxable Income')
                            e.cellElement.classList.add('view-underline');
                    } else if (e.column.name === 'amount2') {
                        if ([
                            'netTaxPayable',
                            'chargeableIncomeAftTaxExemptAmt'
                        ].includes(e.row.data.key)) {
                            e.cellElement.classList.add('view-double-underline');
                        } else if ([
                            'c1_NTDeductibleExp',
                            'c1_FurtherDed',
                            'unutilLossBFNorm',
                            'cyDonation',
                            'taxExemptAmount',
                            'taxRebate'
                        ].includes(e.row.data.key) || e.row.data.description === 'Other Types of Income - Taxable Income') {
                            e.cellElement.classList.add('view-underline');
                        }
                    } else if (e.column.name === 'description') {
                        if ([
                            'Separate Source Income',
                            'Non-Taxable Income',
                            'Non-Tax Deductible Income'
                        ].includes(e.row.data.description) && !e.row.data.amount1 && !e.row.data.amount2)
                            e.cellElement.classList.add('text-decoration-underline');
                    }
                }
            }}
            onEditorPreparing={(e) => {
                if (e.row && e.row.data.component)
                    e.editorElement.parentNode.style.verticalAlign = 'top';
            }}
        />
    );
};

export default TaxComputationView;