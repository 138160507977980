import FormNumberBox from '../components/FormNumberBox';
import FormSelectBox from '../components/FormSelectBox';
import * as formCsStatusConstant from '../../data/formCsStatusConstant';

const PartCView = (props) => {
    let i = 22;
    const {
        initialData,
        status,
        formData,
        handleDraftDataChange
    } = props;

    const isReadOnlyForm = status === formCsStatusConstant.FormSubmitted;

    return (
        <div className="partCView">
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Information from Financial Statement</div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Revenue</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.totalRevenue}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Gross Profit/ Loss</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.grossPL}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Directors' Fees and Remuneration</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.directorFee}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Total Remuneration excluding Directors' Fees</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.totRemuneration}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Medical Expenses</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.medicalExp}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Transport/ Travelling Expenses</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.c1_TransportExp}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Entertainment Expenses</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.c1_EntertainExp}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-2 d-flex align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Inventories</div>
                </div>
                <div className="col-md-6 mb-2 d-flex justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.inventories}
                        readOnly
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Trade Receivables</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={formData.dataFormCS.tradeReceivables}
                        readOnly
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Deduction of Unutilised Capital Allowances/ Losses/ Donations Brought Forward</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Is there a change in the principal activities of the company during the basis period?</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormSelectBox
                        className="col col-md-4"
                        isRequired
                        value={formData.dataFormCS.uCALDChangePrinAct}
                        onValueChanged={e => handleDraftDataChange({ uCALDChangePrinAct: e.value })}
                        readOnly={isReadOnlyForm}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Is there a substantial change in the company's ultimate shareholders and their shareholdings as at the relevant dates?</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormSelectBox
                        className="col col-md-4"
                        isRequired
                        value={formData.dataFormCS.sholderChange}
                        onValueChanged={e => handleDraftDataChange({ sholderChange: e.value })}
                        readOnly={isReadOnlyForm}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Unutilised Capital Allowances/ Losses/ Donations claimed where waiver of the shareholding test has been/ will be granted by IRAS</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.unutilCALDClaimS23S37}
                        onValueChanged={e => handleDraftDataChange({ unutilCALDClaimS23S37: e.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Tax Exemption Scheme for New Start-up Companies</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Has the company satisfied all conditions to qualify for the tax exemption scheme for new start-up companies?</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormSelectBox
                        className="col col-md-4"
                        isRequired
                        value={formData.dataFormCS.fullTxX}
                        onValueChanged={e => handleDraftDataChange({ fullTxX: e.value })}
                        readOnly={isReadOnlyForm}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Deduction for Expenditure on Research and Development (R&D)</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Deduction for expenditure incurred on R&D (undertaken in Singapore and Overseas) - Total deduction under Section 14C</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.expRD}
                        onValueChanged={e => handleDraftDataChange({ expRD: e.value })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Section 14C deduction for expenditure incurred on R&D activities <u>undertaken in Singapore</u></div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.expRDSG}
                        onValueChanged={e => handleDraftDataChange({ expRDSG: e.value })}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Staff costs and consumables for R&D activities <u>undertaken in Singapore</u> qualifying for the additional 150% tax deduction under Section 14D(1) in excess of the $400,000 cap under the Enterprise Innovation Scheme</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.enhanceDeductRD}
                        onValueChanged={e => handleDraftDataChange({ enhanceDeductRD: e.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Appropriation of Trading Stock or Conversion of Non-Trade/ Capital Asset</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Has the company appropriated any trading stock for non-trade or capital purposes under Section 10J and/ or converted any non-trade or capital asset to trading stock under Section 32A?</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormSelectBox
                        className="col col-md-4"
                        isRequired
                        value={formData.dataFormCS.appStockConvAsset}
                        onValueChanged={e => handleDraftDataChange({ appStockConvAsset: e.value })}
                        readOnly={isReadOnlyForm}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Enterprise Innovation Scheme (EIS)</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Is the company claiming cash payout under the EIS in this current YA?</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormSelectBox
                        className="col col-md-4"
                        isRequired
                        value={formData.dataFormCS.eis_ClaimCashPayout}
                        onValueChanged={e => handleDraftDataChange({ eis_ClaimCashPayout: e.value })}
                        readOnly={isReadOnlyForm}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">Is the company claiming enhanced deductions/ allowances under the EIS in this current YA?</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormSelectBox
                        className="col col-md-4"
                        isRequired
                        value={formData.dataFormCS.eis_ClaimDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_ClaimDedAll: e.value })}
                        readOnly={isReadOnlyForm}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Training</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}a.</div>
                    <div className="col">Total Qualifying Cost Incurred</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_TrainTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_TrainTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{i}b.</div>
                    <div className="col">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_TrainDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_TrainDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Innovation Projects Carried Out with Partner Institutions</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}a.</div>
                    <div className="col">Total Qualifying Cost Incurred (capped at $50,000)</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_InnoProjTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_InnoProjTotCost: e.value })}
                        max={50000}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{i}b.</div>
                    <div className="col">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_InnoProjDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_InnoProjDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Acquisition of Intellectual Property Rights (IPRs)</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}a.</div>
                    <div className="col">Total Qualifying Cost Incurred</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_AcqIPRTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_AcqIPRTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{i}b.</div>
                    <div className="col">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_AcqIPRDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_AcqIPRDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Licensing of Intellectual Property Rights (IPRs)</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}a.</div>
                    <div className="col">Total Qualifying Cost Incurred</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_LicensIPRTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_LicensIPRTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{i}b.</div>
                    <div className="col">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_LicensIPRDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_LicensIPRDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Registration of Intellectual Property (IPs)</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}a.</div>
                    <div className="col">Total Qualifying Cost Incurred</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_RegIPTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_RegIPTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{i}b.</div>
                    <div className="col">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_RegIPDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_RegIPDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Qualifying Research and Development (R&D) Undertaken in Singapore</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}a.</div>
                    <div className="col">Total Qualifying Cost Incurred</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_RDSgTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_RDSgTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{i}b.</div>
                    <div className="col">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        readOnly={isReadOnlyForm}
                        value={formData.dataFormCS.eis_RDSgDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_RDSgDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Estimated Tax Payable</div>
            </div>
            <div className="row">
                <div className="col-md-6 d-flex mb-2 align-self-center">
                    <div className="col-2 col-md-1">{++i}.</div>
                    <div className="col">The estimated tax payable is computed based on the declaration in this return. This amount takes into account the Exempt Amount and Corporate Income Tax (CIT) Rebate<sup>1</sup>, where applicable.</div>

                </div>
                <div className="col-md-6 d-flex mb-2 justify-content-end">
                    <FormNumberBox
                        className="col col-md-4"
                        value={initialData.generatedDraft.dataTCSch.netTaxPayable}
                        readOnly
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="form-text d-flex align-items-center"><sup>1</sup>Amount of CIT Rebate is before netting off the amount of CIT Rebate Cash Grant, if applicable.</div>
            </div>
        </div>
    );
};

export default PartCView;