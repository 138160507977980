import { useRef } from 'react';
import { Button } from 'devextreme-react/button';
import { Column, Editing, Toolbar, Selection } from 'devextreme-react/data-grid';
import { ToolbarItem } from 'devextreme-react/popup';
import { SubmitButton } from 'components/StandardButton';
import StandardPopup from 'components/StandardPopup';
import StandardDataGrid from 'components/StandardDataGrid';
import * as Toast from 'utils/toast';
import { useAccountBookData } from 'contexts/AccountBookDataContext';

const FixedAssetImportDialog = (props) => {
    const { initialData, onSubmit, onCancel } = props;

    const { accountBookData } = useAccountBookData();
    const yearOfAssessment = accountBookData.yearOfAssessment;

    const dataGridRef = useRef();

    const onSelectionChanged = (e) => {
        e.currentSelectedRowKeys.forEach(key => {
            const rowData = e.selectedRowsData.find(x => x.id === key);
            if (rowData) {
                if (rowData.capitalAllowanceType === 'Other Property, Plant and Equipment') {
                    if (rowData.acquisitionInYearOfAssessment < yearOfAssessment - 2) {
                        const rowIndex = e.component.getRowIndexByKey(key);
                        e.component.cellValue(rowIndex, 'isDisposed', true);
                        e.component.cellValue(rowIndex, 'disposalInYearOfAssessment', yearOfAssessment);
                    }
                } else {
                    if (rowData.acquisitionInYearOfAssessment !== yearOfAssessment) {
                        const rowIndex = e.component.getRowIndexByKey(key);
                        e.component.cellValue(rowIndex, 'isDisposed', true);
                        e.component.cellValue(rowIndex, 'disposalInYearOfAssessment', yearOfAssessment);
                    }
                }
            }
        });

        e.currentDeselectedRowKeys.forEach(key => {
            const rowIndex = e.component.getRowIndexByKey(key);
            e.component.cellValue(rowIndex, 'isDisposed', false);
            e.component.cellValue(rowIndex, 'disposalInYearOfAssessment', null);
        });

        e.component.saveEditData();
        e.component.repaint();
    };

    return (
        <StandardPopup
            visible
            onHiding={onCancel}
            width="95%"
            height="calc(100vh - 50px)"
            title="Import Entries From Previous YA (Year of Assessment)"
        >
            <div className="fs-6">According to IRAS Rules :</div>
            <ul>
                <li>If Capital Allowance Type <span className="fw-bold">is 'Other Property, Plant and Equipment'</span>, Fixed Asset must either be <span className="d-inline fw-bold">purchased in YA {yearOfAssessment - 2}, {yearOfAssessment - 1} and {yearOfAssessment}</span> or <span className="fw-bold">disposed in YA {yearOfAssessment}.</span></li>
                <li>If Capital Allowance Type <span className="fw-bold">is not 'Other Property, Plant and Equipment'</span>, Fixed Asset must either be <span className="d-inline fw-bold">purchased in YA {yearOfAssessment} </span> or <span className="d-inline fw-bold">disposed in YA {yearOfAssessment}.</span></li>
            </ul>
            <StandardDataGrid
                className="fixed-asset-import-grid"
                keyExpr="id"
                dataSource={initialData}
                height="calc(100% - 115px)"
                repaintChangesOnly
                onEditorPreparing={(e) => {
                    if (e.row && e.row.data && e.dataField === 'isDisposed') {
                        if (
                            e.row.data.capitalAllowanceType === 'Other Property, Plant and Equipment' &&
                            e.component.isRowSelected(e.row.data.id) &&
                            e.row.data.acquisitionInYearOfAssessment >= yearOfAssessment - 2
                        ) {
                            e.editorOptions.readOnly = false;
                        } else {
                            e.editorOptions.readOnly = true;
                        }
                    }
                }}
                onSelectionChanged={onSelectionChanged}
                ref={dataGridRef}
            >
                <Selection mode="multiple" showCheckBoxesMode="always" />
                <Toolbar visible={false} />
                <Editing
                    mode="batch"
                    refreshMode="repaint"
                    allowUpdating
                />
                <Column
                    type="selection"
                    fixed
                    fixedPosition="left"
                />
                <Column
                    caption="Description"
                    dataField="description"
                    dataType="string"
                    allowEditing={false}
                />
                <Column
                    caption="YA of Purchase"
                    dataField="acquisitionInYearOfAssessment"
                    dataType="number"
                    allowEditing={false}
                />
                <Column
                    caption="Cost"
                    dataField="acquisitionPrice"
                    dataType="number"
                    format="#0.00"
                    allowEditing={false}
                />
                <Column
                    caption="Is Disposed"
                    dataField="isDisposed"
                    dataType="boolean"
                    allowEditing
                    setCellValue={(newData, value) => {
                        newData.isDisposed = value;
                        newData.disposalInYearOfAssessment = value ? yearOfAssessment : 0;
                    }}
                />
                <Column
                    caption="YA of Disposal"
                    dataField="disposalInYearOfAssessment"
                    dataType="number"
                    allowEditing={false}
                >
                </Column>
                <Column
                    caption="Capital Allowance Type"
                    dataField="capitalAllowanceType"
                    dataType="string"
                    allowEditing={false}
                />
                <Column
                    caption="Is Hire Purchase (HP)"
                    dataField="isHirePurchase"
                    dataType="boolean"
                    allowEditing={false}
                />
                <Column
                    caption="Principal Paid Before YA Minus 2"
                    dataField="principalPaidBeforeYaMinus2"
                    dataType="number"
                    format="#0.00"
                    allowEditing={false}
                />
                <Column
                    caption="Principal Paid During YA Minus 2"
                    dataField="principalPaidDuringYaMinus2"
                    dataType="number"
                    format="#0.00"
                    allowEditing={false}
                />
                <Column
                    caption="Principal Paid During YA Minus 1"
                    dataField="principalPaidDuringYaMinus1"
                    dataType="number"
                    format="#0.00"
                    allowEditing={false}
                />
                <Column
                    caption="Principal Paid During Current YA"
                    dataField="principalPaidDuringYa"
                    dataType="number"
                    format="#0.00"
                    allowEditing={false}
                />
                <Column
                    caption="Sales Proceed"
                    dataField="disposalPrice"
                    dataType="number"
                    visible={false}
                    format="#0.00"
                    allowEditing={false}
                />
                <Column
                    caption="Remarks"
                    dataField="remark"
                    dataType="string"
                    visible={false}
                    allowEditing={false}
                />
            </StandardDataGrid>
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <Button
                        text="Cancel"
                        onClick={() => onCancel()}
                    />
                )}
            />
            <ToolbarItem
                toolbar="bottom"
                location="after"
                render={() => (
                    <SubmitButton
                        text="Confirm"
                        onClick={() => {
                            const selectedData = dataGridRef.current.instance.getSelectedRowsData();
                            if (selectedData.length === 0)
                                Toast.showErrorMessage('No Fixed Asset Entry is selected.');
                            else
                                onSubmit(dataGridRef.current.instance.getSelectedRowsData());
                        }}
                    />
                )}
            />
        </StandardPopup >
    );
};

export default FixedAssetImportDialog;