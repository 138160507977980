import { Validator, CustomRule } from 'devextreme-react/validator';
import StandardDateBox from 'components/StandardDateBox';
import FormNumberBox from '../components/FormNumberBox';
import FormSelectBox from '../components/FormSelectBox';

const TaxAdjustmentView = (props) => {
    const {
        formData,
        handleTaxConversionDataChange, handleDraftDataChange,
        validatorErrors
    } = props;

    return (
        <>
            <div className="row mb-2">
                <div className="fs-6 align-self-center fw-bolder">Deduction for Renovation or Refurbishment Works under Section 14N</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">First YA in which Leasehold Improvements and Renovation Cost was Incurred and Section 14N Deduction Claimed</div>
                <div className="col-4 ">
                    <StandardDateBox
                        value={formData.taxConversion.firstYAInWhichS14QDeductionClaimed ? new Date(formData.taxConversion.firstYAInWhichS14QDeductionClaimed, 0) : null}
                        calendarOptions={{
                            maxZoomLevel: 'decade',
                            minZoomLevel: 'century'
                        }}
                        displayFormat="yyyy"
                        useMaskBehavior
                        onValueChanged={(e) => {
                            if (e.value === null) {
                                handleTaxConversionDataChange({ firstYAInWhichS14QDeductionClaimed: e.value });
                            } else {
                                const year = e.value && new Date(e.value).getFullYear();
                                handleTaxConversionDataChange({ firstYAInWhichS14QDeductionClaimed: year });
                            }
                        }}
                        showClearButton
                    >
                        <Validator validationGroup="initialView">
                            <CustomRule
                                validationCallback={e => {
                                    const index = validatorErrors.findIndex(x => x.field === 'firstYAInWhichS14QDeductionClaimed');
                                    if (index >= 0) {
                                        e.rule.message = validatorErrors[index].message;
                                        validatorErrors.splice(index, 1);
                                    }
                                    return index < 0;
                                }}
                            />
                        </Validator>
                    </StandardDateBox>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Does the Leasehold Improvements and Renovation Cost Require the Approval from the Commisioner of Building Control under the Building Control Act?</div>
                <div className="col-4 ">
                    <FormSelectBox
                        value={formData.taxConversion.theLeaseholdImprovementsAndRenoCostDoNotRequireTheApprovalOfCOBC}
                        onValueChanged={e => handleTaxConversionDataChange({ theLeaseholdImprovementsAndRenoCostDoNotRequireTheApprovalOfCOBC: e.value })}
                    >
                        <Validator validationGroup="initialView">
                            <CustomRule
                                validationCallback={e => {
                                    const index = validatorErrors.findIndex(x => x.field === 'theLeaseholdImprovementsAndRenoCostDoNotRequireTheApprovalOfCOBC');
                                    if (index >= 0) {
                                        e.rule.message = validatorErrors[index].message;
                                        validatorErrors.splice(index, 1);
                                    }
                                    return index < 0;
                                }}
                            />
                        </Validator>
                    </FormSelectBox>
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 align-self-center fw-bolder">Deduction of Unutilised Capital Allowances/ Losses/ Donations Brought Forward</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">Is there a change in the principal activities of the company during the basis period ?</div>
                <div className="col-4 ">
                    <FormSelectBox
                        isRequired
                        value={formData.dataFormCS.uCALDChangePrinAct}
                        onValueChanged={e => handleDraftDataChange({ uCALDChangePrinAct: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">Is there a substantial change in the company's ultimate shareholders and their shareholdings as at the relevant dates ?</div>
                <div className="col-4 ">
                    <FormSelectBox
                        isRequired
                        value={formData.dataFormCS.sholderChange}
                        onValueChanged={e => handleDraftDataChange({ sholderChange: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Unutilised Capital Allowances/ Losses/ Donations claimed where waiver of the shareholding test has been/will be granted by IRAS</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.unutilCALDClaimS23S37}
                        onValueChanged={(e) => handleDraftDataChange({ unutilCALDClaimS23S37: e.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Deduction of Capital Allowances on Assets Acquired Prior to Adopting Seamless Filing Solution</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Initial or Annual Allowance (Assets acquired prior to Seamless Filing Solution)</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.taxConversion.iaAaPriorSeamlessFiling}
                        onValueChanged={e => handleTaxConversionDataChange({ iaAaPriorSeamlessFiling: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Balancing Allowance (Assets acquired prior to Seamless Filing Solution)</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.taxConversion.baPriorSeamlessFiling}
                        onValueChanged={e => handleTaxConversionDataChange({ baPriorSeamlessFiling: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Balancing Charge (Assets acquired prior to Seamless Filing Solution)</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.taxConversion.bcPriorSeamlessFiling}
                        onValueChanged={e => handleTaxConversionDataChange({ bcPriorSeamlessFiling: e.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Tax Exemption Scheme for New Start-up Companies</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Has the company satisfied all conditions to qualify for the tax exemption scheme for new start-up companies ?</div>
                <div className="col-4 ">
                    <FormSelectBox
                        isRequired
                        value={formData.dataFormCS.fullTxX}
                        onValueChanged={e => handleDraftDataChange({ fullTxX: e.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Deduction of Expenditure on Research and Development (R&D)</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Deduction for expenditure incurred on R&D (undertaken in Singapore and Overseas) - Total deduction under Section 14C</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.expRD}
                        onValueChanged={e => handleDraftDataChange({ expRD: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Section 14C deduction for expenditure incurred on R&D activities undertaken in Singapore</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.expRDSG}
                        onValueChanged={e => handleDraftDataChange({ expRDSG: e.value })}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Staff costs and consumables for R&D activities undertaken in Singapore qualifying for the additional 150% tax deduction under Section 14D(1) in excess of the $400,000 cap under the Enterprise Innovation Scheme</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.enhanceDeductRD}
                        onValueChanged={e => handleDraftDataChange({ enhanceDeductRD: e.value })}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 fw-bolder">Appropriation of Trading Stock or Conversion of Non-Trade/ Capital Asset</div>
            </div>
            <div className="row">
                <div className="col-md-8 mb-2">Has the company appropriated any trading stock for non-trade or capital purposes under Section 10J and/ or converted any non-trade or capital asset to trading stock under Section 32A ?</div>
                <div className="col-md-4 mb-2">
                    <FormSelectBox
                        isRequired
                        value={formData.dataFormCS.appStockConvAsset}
                        onValueChanged={e => handleDraftDataChange({ appStockConvAsset: e.value })}
                    >
                        <Validator validationGroup="initialView">
                            <CustomRule
                                validationCallback={e => {
                                    const index = validatorErrors.findIndex(x => x.field === 'appStockConvAsset');
                                    if (index >= 0) {
                                        e.rule.message = validatorErrors[index].message;
                                        validatorErrors.splice(index, 1);
                                    }
                                    return index < 0;
                                }}
                            />
                        </Validator>
                    </FormSelectBox>
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 fw-bolder">Enterprise Innovation Scheme (EIS)</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">Is the company claiming cash payout under the EIS in this current YA ?</div>
                <div className="col-4 ">
                    <FormSelectBox
                        isRequired
                        value={formData.dataFormCS.eis_ClaimCashPayout}
                        onValueChanged={e => handleDraftDataChange({ eis_ClaimCashPayout: e.value })}
                    >
                    </FormSelectBox>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">Is the company claiming enhanced deductions/ allowances under the EIS in this current YA ?</div>
                <div className="col-4 ">
                    <FormSelectBox
                        isRequired
                        value={formData.dataFormCS.eis_ClaimDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_ClaimDedAll: e.value })}
                    >
                    </FormSelectBox>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">
                    Enhanced Deductions under Enterprise Innovation Scheme (EIS) for:
                    <ul className="mt-2">
                        <li>Training;</li>
                        <li>Innovation Projects carried out with Partner Institutions;</li>
                        <li>Licensing of Intellectual Property Rights;</li>
                        <li>Registration of Intellectual Property;</li>
                        <li>Qualifying R&D undertaken in Singapore;</li>
                    </ul>
                </div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.c1_EnhancedEISDed}
                        onValueChanged={e => handleDraftDataChange({ c1_EnhancedEISDed: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 align-self-center">Enhanced Allowance under Enterprise Innovation Scheme (EIS) for Acquisition of Intellectual Property Rights (IPRs)</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.taxConversion.enhancedEISCA}
                        onValueChanged={e => handleTaxConversionDataChange({ enhancedEISCA: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Training</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Total Qualifying Cost Incurred</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_TrainTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_TrainTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_TrainDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_TrainDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Innovation Projects Carried Out with Partner Institutions</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Total Qualifying Cost Incurred (capped at $50,000)</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_InnoProjTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_InnoProjTotCost: e.value })}
                        max={50000}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_InnoProjDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_InnoProjDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Acquisition of Intellectual Property Rights (IPRs)</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Total Qualifying Cost Incurred</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_AcqIPRTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_AcqIPRTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_AcqIPRDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_AcqIPRDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Licensing of Intellectual Property Rights (IPRs)</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Total Qualifying Cost Incurred</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_LicensIPRTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_LicensIPRTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_LicensIPRDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_LicensIPRDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Registration of Intellectual Property (IPs)</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Total Qualifying Cost Incurred</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_RegIPTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_RegIPTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_RegIPDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_RegIPDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <hr />
            <div className="row mb-2">
                <div className="fs-6 d-flex align-items-center fw-bolder">Qualifying Research and Development (R&D) Undertaken in Singapore</div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Total Qualifying Cost Incurred</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_RDSgTotCost}
                        onValueChanged={e => handleDraftDataChange({ eis_RDSgTotCost: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 fs-7 d-flex align-items-center">Enhanced Deductions/ Allowances Claimed/ To Be Claimed</div>
                <div className="col-4 ">
                    <FormNumberBox
                        value={formData.dataFormCS.eis_RDSgDedAll}
                        onValueChanged={e => handleDraftDataChange({ eis_RDSgDedAll: e.value })}
                        disabled={formData.dataFormCS.eis_ClaimDedAll === 2}
                    />
                </div>
            </div>
        </>
    );
};

export default TaxAdjustmentView;